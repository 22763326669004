<template>
    <div id="adminDashboard" v-if="authState=='signedin' && user.signInUserSession.accessToken.payload['cognito:groups'][0]=='admins'">
        <div class="dashboardcontent">
            <h1>ADMIN DASHBOARD</h1>

            <div class="buttons">
                  
                    <button
                    class="text-light btn me-2" style="background:#029676" @click="showCreateNewUser" v-if="showUsersTable"
                    
                    ><i class="bi bi-plus-circle"></i> Create New User</button>
                    <button class="btn text-light" style="background:#002060" v-if="!showUsersTable" @click="showCreateNewUser"> Back To Users Dashboard</button>
                
              
                  
                  

                    
                  
                  
            </div>
        </div>

        <table class="table table-bordered " v-if="dataLoaded && showUsersTable">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Username</th>
                <th scope="col">Email</th>
                <th scope="col">User status</th>
                <th scope="col">License Is Active ?</th>
                <th scope="col">Actions</th>
                </tr>
            </thead>
        
            <tbody v-for="(userr, index) in allUsers" :key="userr">
                <tr >
                    <th scope="row">{{index +1 }}</th>
                    <td>{{userr.Username}}</td>
                    <td>{{userr.email}}</td>
                    <td>{{userr.UserStatus}}</td>
                    <td >{{userr['custom:license_is_active']}}</td>
                    
                    <td scope="col" v-if="userInfoToShow!=index" > <button class="btn btn-primary me-2" @click="showMoreUserInfo(index)">More Info</button><button class="btn btn-success me-2" @click="showUpdateLicense(userr)">Upgrade License</button><button @click="removeUser(userr.Username)" class="btn btn-danger">Remove</button></td>
                    <td scope="col" v-if="userInfoToShow==index"> <button class="btn btn-secondary me-2" @click="showLessUserInfo()">Less Info</button><button class="btn btn-success me-2" @click="showUpdateLicense(userr)">Upgrade License</button><button @click="removeUser(userr.Username)" class="btn btn-danger">Remove</button> </td>
                    
                </tr>
                <tr v-if="userInfoToShow==index">
                    <td colspan="6" class="">
                        <table class="table w-90">
                        
                            
                        
                            <tr>
                                <th scope="col" colspan="1" class="text-center">Number of projects allowed</th>
                                <th scope="col" colspan="" class="text-center">Number of overall iterations allowed</th>
                                <th scope="col" colspan="" class="text-center">Number of iterations per project allowed</th>
                                <th scope="col" colspan="" class="text-center">Number of active projects allowed</th>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    {{userr['custom:num_proj_alw']}}
                                </td>
                                <td class="text-center">
                                    {{userr['custom:num_all_iter_alw']}}
                                </td>
                                <td class="text-center">
                                    {{userr['custom:num_iter_per_pro_alw']}}
                                </td>
                                <td class="text-center">
                                    {{userr['custom:num_act_proj_alw']}}
                                </td>

                                
                            </tr>
                            <br>
                            <tr>
                                <th scope="col" colspan="1" class="text-center">License started</th>
                                <th scope="col" colspan="1" class="text-center">License ends</th>
                                <th scope="col" colspan="1" class="text-center">License duration (days)</th>
                                <th scope="col" colspan="1" class="text-center"> Created by</th>
                                <th scope="col" colspan="1" class="text-center">Last updated by</th>
                                
                            </tr>
                            <tr>
                                <td class="text-center">
                                    {{userr['custom:lic_start']}}
                                </td>
                                <td class="text-center">
                                    {{userr['custom:lic_end']}}
                                </td>
                                <td class="text-center">
                                    {{userr['custom:lic_dur_in_days']}}
                                </td>
                                <td class="text-center">
                                    {{userr["custom:created_by"]}}    
                                </td>
                                <td class="text-center">
                                    {{userr["custom:updated_by"]}}
                                </td>
                                
                            </tr>
                        <tbody>

                        </tbody>
                    </table>
                    </td>
                </tr>
            </tbody>   
        </table>
        <div id="newUserForm" v-if="!showUsersTable">
            <div class="formTitle" v-if="!updateUser"><h1>Add new user </h1></div>
            <div class="formTitle" v-if="updateUser"><h1>Update user's quota </h1></div>
            <div id="firstStep" v-if="showFirstStep">
                <div class="form-floating mb-3">
                    <input type="text" v-model="newUserLicense.user_name" class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">User Name</label>
                    <div v-if="v$.newUserLicense.user_name.$error" class="text-danger"> Username is required</div>
                </div>
                <div class="form-floating mb-3 ">
                    <input type="email" v-model="newUserLicense.email" class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">Email address</label>
                    <div v-if="v$.newUserLicense.email.$error" class="text-danger"> Email is required</div>

                </div>
                <h2>Select type of license</h2>
                <div class="form-check">
                    <input class="form-check-input" v-model="newUserLicense.on_trial_mode" value=true type="radio" name="flexRadioDefault" id="flexRadioDefaultTrial" checked>
                    <label class="form-check-label" for="flexRadioDefaultTrial">
                        Trial License
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" v-model="newUserLicense.on_trial_mode" value=false type="radio" name="flexRadioDefault" id="flexRadioDefaultOfficial" >
                    <label class="form-check-label" for="flexRadioDefaultOfficiak">
                        Customized License
                    </label>
                </div>
            </div>
            <div id="SecondStep" v-if="showSecondStep">
                <div v-if="trialMode">
                     <h3 class="text-center" v-if="!updateUser"> License Quota</h3>
                    <table class="table" v-if="updateUser">
                        <thead>
                            <tr>
                                <th scope="col">Username</th>
                                <td>{{updateUserLicense.user_name}}</td>
                            </tr>
                            <tr>
                                <th scope="row">Email</th>
                                <td>
                                    {{updateUserLicense.email}}
                                </td>
                            </tr>
                        </thead>
                    </table>
                    <table class="table ">
                        <thead>
                                <tr v-if="updateUser">
                                    <th colspan="2">Attributes</th>
                                    <th>Updated license</th>
                                    <th>Original license</th>
                                </tr>
                                <tr v-if="!updateUser">
                                    <th scope="row" colspan="2">
                                    Username
                                    </th>
                                    <td>{{newUserLicense.user_name}}</td>
                                </tr>
                                <tr v-if="!updateUser">
                                    <th scope="row" colspan="2">
                                    Email
                                    </th>

                                    <td>{{newUserLicense.email}}</td>
                                </tr>
                                <tr>

                                    <th scope="row" colspan="2">
                                        Number of projects allowed
                                    </th>
                                    <td v-if="updateUser">{{updateUserLicense.number_of_projects_allowed}}</td>
                                    <td>{{newUserLicense.number_of_projects_allowed}}</td>
                                </tr>
                                <tr>

                                    <th scope="row" colspan="2">
                                        Number of overall iteration allowed
                                    </th>
                                    <td v-if="updateUser">{{updateUserLicense.number_of_overall_iterations_allowed}}</td>
                                    <td>{{newUserLicense.number_of_overall_iterations_allowed}}</td>
                                </tr>
                                <tr>

                                    <th scope="row" colspan="2">
                                        Number of iterations per project allowed
                                    </th>
                                    <td v-if="updateUser">{{updateUserLicense.number_of_iterations_per_project_allowed}}</td>
                                    <td>{{newUserLicense.number_of_iterations_per_project_allowed}}</td>
                                </tr>
                                <tr>

                                    <th scope="row" colspan="2">
                                        Number of active projects
                                    </th>
                                    <td v-if="updateUser">{{updateUserLicense.number_of_active_projects_allowed}}</td>
                                    <td>{{newUserLicense.number_of_active_projects_allowed}}</td>
                                </tr>
                                <tr>

                                    <th scope="row" colspan="2">
                                        License duration in days
                                    </th>
                                    <td v-if="updateUser">{{updateUserLicense.license_duration_in_days}}</td>
                                    <td>{{newUserLicense.license_duration_in_days}}</td>
                                </tr>
                                
                            
                        </thead>
                    </table>
                </div>
                <div v-if="!trialMode && !updateUser">
                    <h3 class="text-center">Personalized License Quota</h3>
                    <table class="table">
                        <tr>
                                    <th scope="row">
                                    Username
                                    </th>
                                    <td>{{newUserLicense.user_name}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                    Email
                                    </th>
                                    <td>{{newUserLicense.email}}</td>
                                </tr>
                    </table>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="newUserLicense.number_of_projects_allowed" class="form-control" id="floatingInput11" placeholder="name@example.com">
                        <label for="floatingInput11">Number Of Projects Allowed</label>
                        <div v-if="v$.newUserLicense.number_of_projects_allowed.$error" class="text-danger"> Required, Positive Integer</div>

                    </div>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="newUserLicense.number_of_overall_iterations_allowed" class="form-control" id="floatingInput22" placeholder="name@example.com">
                        <label for="floatingInput22">Number Of Overall Iterations Allowed</label>
                        <div v-if="v$.newUserLicense.number_of_overall_iterations_allowed.$error" class="text-danger"> Required, Positive Integer</div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="newUserLicense.number_of_iterations_per_project_allowed" class="form-control" id="floatingInput33" placeholder="name@example.com">
                        <label for="floatingInput33">Number Of Iterations Per Project Allowed</label>
                        <div v-if="v$.newUserLicense.number_of_iterations_per_project_allowed.$error" class="text-danger"> Required, Positive Integer</div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="newUserLicense.number_of_active_projects_allowed" class="form-control" id="floatingInpu44" placeholder="name@example.com">
                        <label for="floatingInput44">Number Of Active Project Allowed</label>
                        <div v-if="v$.newUserLicense.number_of_active_projects_allowed.$error" class="text-danger"> Required, Positive Integer</div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="newUserLicense.license_duration_in_days" class="form-control" id="floatingInput55" placeholder="name@example.com">
                        <label for="floatingInput55">License Duration (Days)</label>
                        <div v-if="v$.newUserLicense.license_duration_in_days.$error" class="text-danger"> Required, Positive Integer</div>
                    </div>
                    <div class="d-flex flex-row justify-content-center">
                        <button class=" btn btn-primary" @click="confitmDataOfUser" :disabled="!isNewUserFormValid"> Confirm</button>
                    </div>
                </div><div v-if="!trialMode && updateUser">
                    <table class="table">
                        <tr>
                                    <th scope="row">
                                    Username
                                    </th>
                                    <td>{{updateUserLicense.user_name}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                    Email
                                    </th>
                                    <td>{{updateUserLicense.email}}</td>
                                </tr>
                    </table>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="updateUserLicense.number_of_projects_allowed" class="form-control" id="floatingInput11" placeholder="name@example.com">
                        <label for="floatingInput11">Number Of Projects Allowed</label>
                        <div v-if="v$.updateUserLicense.number_of_projects_allowed.$error" class="text-danger"> Required, Positive Integer</div>

                    </div>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="updateUserLicense.number_of_overall_iterations_allowed" class="form-control" id="floatingInput22" placeholder="name@example.com">
                        <label for="floatingInput22">Number Of Overall Iterations Allowed</label>
                        <div v-if="v$.updateUserLicense.number_of_overall_iterations_allowed.$error" class="text-danger"> Required, Positive Integer</div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="updateUserLicense.number_of_iterations_per_project_allowed" class="form-control" id="floatingInput33" placeholder="name@example.com">
                        <label for="floatingInput33">Number Of Iterations Per Project Allowed</label>
                        <div v-if="v$.updateUserLicense.number_of_iterations_per_project_allowed.$error" class="text-danger"> Required, Positive Integer</div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="updateUserLicense.number_of_active_projects_allowed" class="form-control" id="floatingInpu44" placeholder="name@example.com">
                        <label for="floatingInput44">Number Of Active Project Allowed</label>
                        <div v-if="v$.updateUserLicense.number_of_active_projects_allowed.$error" class="text-danger"> Required, Positive Integer</div>
                    </div>
                    <div class="form-floating mb-3">
                        <input type="number" v-model="updateUserLicense.license_duration_in_days" class="form-control" id="floatingInput55" placeholder="name@example.com">
                        <label for="floatingInput55">License Duration (Days)</label>
                        <div v-if="v$.updateUserLicense.license_duration_in_days.$error" class="text-danger"> Required, Positive Integer</div>
                    </div>
                    <div class="d-flex flex-row justify-content-center" v-if="!updateUser">
                        <button class=" btn btn-primary" @click="confitmDataOfUser" :disabled="!isNewUserFormValid "> Confirm</button>
                    </div>
                    <div class="d-flex flex-column justify-content-between align-items-center" v-if="updateUser">
                        <button class=" btn btn-primary mb-2" @click="confitmDataOfUser" :disabled="!isUpdateUserFormValid "> Confirm</button>
                        <button class="btn btn-sm btn-warning" @click="resetUpdateToInitialValues()">Reset to initial values</button>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-row justify-content-end" v-if="showFirstStep">
                <button class="btn btn-primary" @click="nextStep" :disabled="v$.newUserLicense.user_name.$error || v$.newUserLicense.email.$error ">Next</button>
            </div>
            <div class="d-flex flex-row justify-content-end" v-if="showSecondStep && trialMode && userNotCreated">
                <button class="btn btn-success" v-if="!updateUser" @click="submitNewUser" :disabled="!isNewUserFormValid">Create User</button>
                <button class="btn btn-success" v-if="updateUser" @click="submitUpdateUser" :disabled="!isUpdateUserFormValid"> Update User</button>
            </div>
            <div class="d-flex flex-row justify-content-end" v-if="showSecondStep && trialMode && !userNotCreated">
                <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import {Auth} from "aws-amplify"
import useVuelidate from '@vuelidate/core'
import {integer,email, maxLength, maxValue, minLength, minValue, numeric, required, requiredIf} from '@vuelidate/validators'

export default {
    name:'Admin',
    setup() {
        return {

        v$: useVuelidate()
        }
    },
    data(){
        return{
            authState:'', 
            session:"",
            authHeader:'',
            user:'',
            allUsers:[],
            numberOfUsers:'',
            dataLoaded:false,
            showUsersTable:true,
            showFirstStep:true,
            showSecondStep:false,
            updateUser:false,
            newUserLicense:{
                "user_name":"",
                "email":"",
                "is_new_user":true,
                "on_trial_mode":true,
                "license_is_active":true,
                "number_of_projects_allowed":"",
                "number_of_overall_iterations_allowed":"",
                "number_of_iterations_per_project_allowed":"",
                "number_of_active_projects_allowed":"",
                "license_duration_in_days":""

            },
            updateUserLicense:{
                "user_name":"",
                "email":"",
                "is_new_user":false,
                "on_trial_mode":true,
                "license_is_active":true,
                "number_of_projects_allowed":"",
                "number_of_overall_iterations_allowed":"",
                "number_of_iterations_per_project_allowed":"",
                "number_of_active_projects_allowed":"",
                "license_duration_in_days":""
            },
            originalUserLicense:{},
            trialMode:"",
            userInfoToShow:-1,
            userNotCreated:true

        }
    },
    validations(){
        return{
            newUserLicense:{
                "user_name":{
                    required,
                    $autoDirty: true
                },
                "email":{
                    required,
                    email,
                    $autoDirty: true
                },
                "number_of_projects_allowed":{
                    required,
                    minValue:minValue(1),
                    integer,
                    $autoDirty: true
                },
                "number_of_overall_iterations_allowed":{
                    required,
                    minValue:minValue(1),
                    integer,
                    $autoDirty: true
                },
                "number_of_iterations_per_project_allowed":{
                    required,
                    minValue:minValue(1),
                    integer,
                    $autoDirty: true
                },
                "number_of_active_projects_allowed":{
                    required,
                    minValue:minValue(1),
                    integer,
                    $autoDirty: true
                },
                "license_duration_in_days":{
                    required,
                    minValue:minValue(1),
                    integer,
                    $autoDirty: true
                }
            },
            updateUserLicense:{
                "user_name":{
                    required
                },
                "email":{
                    required,
                    email
                },
                "number_of_projects_allowed":{
                    required,
                    minValue:minValue(1),
                    integer
                },
                "number_of_overall_iterations_allowed":{
                    required,
                    minValue:minValue(1),
                    integer
                },
                "number_of_iterations_per_project_allowed":{
                    required,
                    minValue:minValue(1),
                    integer
                },
                "number_of_active_projects_allowed":{
                    required,
                    minValue:minValue(1),
                    integer
                },
                "license_duration_in_days":{
                    required,
                    minValue:minValue(1),
                    integer
                }
            }
        }
    },
    methods:{
        async getUsers(){
            const headers = {
                'Content-Type': "application/json",
                Authorization: this.authHeader
            }

            const response = await fetch(
                "https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/users",
                {
                    method: "GET",
                    headers
                    // body: JSON.stringify(this.dataToSend)
                }
            );

            const resp = await response.json()
            this.allUsers = resp["users"]
            this.numberOfUsers = this.allUsers.length
            this.dataLoaded = true
        },
        nextStep(){
            if(this.newUserLicense.on_trial_mode==='true' || this.newUserLicense.on_trial_mode==true){
                this.trialMode=true
                
                this.newUserLicense["number_of_projects_allowed"]=2
                this.newUserLicense["number_of_overall_iterations_allowed"]=4
                this.newUserLicense["number_of_iterations_per_project_allowed"]=3
                this.newUserLicense["number_of_active_projects_allowed"]=1
                this.newUserLicense["license_duration_in_days"]=7

                
            }
            else{
                this.trialMode=false
                this.newUserLicense["number_of_projects_allowed"]=""
                this.newUserLicense["number_of_overall_iterations_allowed"]=""
                this.newUserLicense["number_of_iterations_per_project_allowed"]=""
                this.newUserLicense["number_of_active_projects_allowed"]=""
                this.newUserLicense["license_duration_in_days"]=""
            }
            this.showFirstStep=false
            this.showSecondStep=true
        },
        confitmDataOfUser(){
            
            if(this.updateUser){
                this.newUserLicense=this.originalUserLicense
            }
            
                this.trialMode = true
            

        },
        showCreateNewUser(){
            this.showUsersTable= !this.showUsersTable
            if(this.showUsersTable){
                this.showFirstStep=true
                this.showSecondStep=false
                this.newUserLicense={
                    "user_name":"",
                    "email":"",
                    "is_new_user":"yes",
                    "on_trial_mode":true,
                    "license_is_active":true,
                    "number_of_projects_allowed":"",
                    "number_of_overall_iterations_allowed":"",
                    "number_of_iterations_per_project_allowed":"",
                    "number_of_active_projects_allowed":"",
                    "license_duration_in_days":""

                }
            }
        },
        showUpdateLicense(user){
            this.showUsersTable=false
            this.showFirstStep=false
            this.showSecondStep=true
            this.trialMode=false
            this.updateUser=true
            
            this.originalUserLicense = {
                "user_name":user.Username,
                "email":user.email,
                "is_new_user":false,
                "on_trial_mode":false,
                "license_is_active":true,
                "number_of_projects_allowed":user["custom:num_proj_alw"],
                "number_of_overall_iterations_allowed":user["custom:num_all_iter_alw"],
                "number_of_iterations_per_project_allowed":user["custom:num_iter_per_pro_alw"],
                "number_of_active_projects_allowed":user["custom:num_act_proj_alw"],
                "license_duration_in_days":user["custom:lic_dur_in_days"],
                "license_starts":user["custom:lic_start"]
            }
            this.updateUserLicense={
                "user_name":this.originalUserLicense.user_name,
                "email":this.originalUserLicense.email,
                "is_new_user":false,
                "on_trial_mode":false,
                "license_is_active":true,
                "number_of_projects_allowed":this.originalUserLicense.number_of_projects_allowed,
                "number_of_overall_iterations_allowed":this.originalUserLicense.number_of_overall_iterations_allowed,
                "number_of_iterations_per_project_allowed":this.originalUserLicense.number_of_iterations_per_project_allowed,
                "number_of_active_projects_allowed":this.originalUserLicense.number_of_active_projects_allowed,
                "license_duration_in_days":this.originalUserLicense.license_duration_in_days
            }
        },
        resetUpdateToInitialValues(){
            this.updateUserLicense = {
                "user_name":this.originalUserLicense.user_name,
                "email":this.originalUserLicense.email,
                "is_new_user":false,
                "on_trial_mode":false,
                "license_is_active":true,
                "number_of_projects_allowed":this.originalUserLicense.number_of_projects_allowed,
                "number_of_overall_iterations_allowed":this.originalUserLicense.number_of_overall_iterations_allowed,
                "number_of_iterations_per_project_allowed":this.originalUserLicense.number_of_iterations_per_project_allowed,
                "number_of_active_projects_allowed":this.originalUserLicense.number_of_active_projects_allowed,
                "license_duration_in_days":this.originalUserLicense.license_duration_in_days
            }
        },
        async submitNewUser(){
            this.userNotCreated = false
            const headers = {
                'Content-Type': "application/json",
                Authorization: this.authHeader
            }
            const response = await fetch(
                "https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/users",
                {
                    method: "POST",
                    headers,
                    body: JSON.stringify(this.newUserLicense)
                }
            );

            const createUserResponse = await response.json()
            this.getUsers()
            this.showUsersTable = true
            this.userNotCreated=true
            this.showFirstStep=true
            this.showSecondStep=false
        },
        async submitUpdateUser(){
            this.userNotCreated = false
            const headers = {
                'Content-Type': "application/json",
                Authorization: this.authHeader
            }
            const bodyToSend = {
                "is_new_user":this.updateUserLicense.is_new_user,
                "original_license":this.originalUserLicense,
                "updated_license":this.updateUserLicense
            }
            const response = await fetch(
                "https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/users",
                {
                    method: "POST",
                    headers,
                    body: JSON.stringify(bodyToSend)
                }
            );
            const updateUserResponse = await response.json()
            this.getUsers()
            this.showUsersTable = true
            this.userNotCreated = true
            this.updateUser = false
        },
        showMoreUserInfo(value){
            this.userInfoToShow = value
            
        },
        showLessUserInfo(){
            this.userInfoToShow = -1
        },
        async removeUser(userToRemove){
            const a = confirm(`Do you want to remove the user : ${userToRemove} ?`)
            if (a){
                const headers = {
                    'Content-Type': "application/json",
                    Authorization: this.authHeader
                }
                const response = await fetch(
                "https://gbjaiz8v12.execute-api.us-west-2.amazonaws.com/users",
                {
                    method: "DELETE",
                    headers,
                    body: JSON.stringify({"user_name":userToRemove})
                }
            );
                const b = await response.json()
                this.getUsers()

            }
        }
    },
    computed:{
        isUpdateUserFormValid(){
            this.v$.updateUserLicense.$touch()
            return !this.v$.updateUserLicense.$error
        },
        isNewUserFormValid(){
            this.v$.newUserLicense.$touch()
            return !this.v$.newUserLicense.$error
        }
    },
    async created() {
        try{

            this.user = await Auth.currentAuthenticatedUser();
            
            this.session = await Auth.currentSession();
            this.authHeader = `Bearer ${this.session.idToken.jwtToken}`;
            this.authState = "signedin"
            if(this.user.signInUserSession.accessToken.payload['cognito:groups'][0]=='normal_users'){
                this.$router.push("/")
            }
            this.getUsers()
            this.v$.$touch()
        }
        catch{
            this.authState = 'not'
            this.$router.push("/")
        }

    
    },
    
    
}
</script>
<style scoped>
#adminDashboard{
    min-height: 100vh;

    width: 100vw;
    background-image: linear-gradient(120deg, #193c7091 0%, #002060 150%),
    url("../assets/dash2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 3rem;
}
.dashboardcontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  width: 80%;
  
  padding: 0 3%;
  border-radius: 10px;
  margin:  auto;
}
.table{
    background-color: #f0f0f0;
    margin: 5rem auto;
    width: 80%;
}

#newUserForm {
  background-color: rgba(255, 255, 255, .45);
  padding: 1.5rem 4rem;
  border-radius: 20px;
  font-size: 20px;
  min-width: 500px;
  width: 60rem;
  margin:  8rem auto;


}
.formTitle{
    background-color: #f0f0f0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin: 0rem 1rem 2rem 1rem;
}
#secondStep, .table{
    width: 100%;}
</style>